import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Youtube = makeShortcode("Youtube");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`GoodNotesを最近よく使ってるので何にどういう風に使っているのかという紹介です。`}</p>
      <p>{`自分は毎日では無いけど、このGoodNotesを日々使っている。GoodNotesってどういう風に使うのかとか、Apple Pencilって使うの？とか思っている人が参考になる可能性が無きにしもあらずです。`}</p>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "goodnotesとは"
      }}>{`GoodNotesとは`}</h2>
      <p>{`iPadのソフトです。Apple Pencilで使うことをほぼ前提にしている。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.goodnotes.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`GoodNotes`}</a></li>
      </ul>
      <p>{`これがあるとiPadでノートが取れます。`}</p>
      <h2 {...{
        "id": "ipadとapple-pencilと私"
      }}>{`iPadとApple Pencilと私`}</h2>
      <p>{`自分が使っているのは12.9inchのデカいiPadなんですが、これを買う時、なんかApple Pencilもあったら便利かもな〜と思って一緒に買いました。`}</p>
      <p>{`しかし最初はApple Pencilはほぼ使わずずっと引き出しに眠っているだけ。iPadはYoutubeとUdemy再生専用機みたいになっていたものの、色々あって最近は仕事に趣味によく使うようになっている。`}<br parentName="p"></br>{`
`}{`今回はそのiPadを使う用途の中で、GoodNotesについて。`}</p>
      <h2 {...{
        "id": "アイデアをざっくり書くのに使う"
      }}>{`アイデアをざっくり書くのに使う`}</h2>
      <p>{`はじめにGoodNotesを気分で買ってみて、何か書いていた形跡があるのはこれでした。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/ca0dd65c-bc84-4e02-a810-4b3aaebd178a_gnotes1.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/2cf78f59-7890-43d0-9382-6a2b308488b3_gnotes2.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p>{`何か時間管理みたいなアプリと、Trelloのカードのフィードみたいのを作ろうとしていた模様。考えてただけですが。`}</p>
      <p>{`こういうのを「どうしよっかなー」って考えるとき、紙に書いてみたりすることがあると思うんですが、これは試しにGoodNotesってやつでやってみたら良いんじゃない？って思ってやったやつです。`}</p>
      <p>{`この時点ではただ「やってみた」だけですが、PhotoshopやらPowerPointやら、いくらでも他に候補があるところを、手書きでやってみようとしていることから、手で書くと考えが整理されるという側面があるというのを、ちょっと感じていたのかもしれない。まぁなんのことはない、ただ何か作ろうとする時は、はじめにチラシの裏にでも書いてみるとイメージ湧くかもねレベルの話です。`}</p>
      <h2 {...{
        "id": "セミナーのスライドを作るのに使う"
      }}>{`セミナーのスライドを作るのに使う`}</h2>
      <p>{`その後も`}<a parentName="p" {...{
          "href": "https://www.codegrid.net/series/2021-amplify-console",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`原稿`}</a>{`を書く時になんかに、構成を考えるためになんとなく、たまに使っていた。こんな感じに。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/5c686292-45ed-44e7-9217-e72a408d4be6_gnots3.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p>{`これわいわゆるマインドマップというやつです。`}<br parentName="p"></br>{`
`}{`そんな風に、何かを書いたりする前に、ちょっと書いてみるという用途で使っていた。`}</p>
      <p>{`で、`}<a parentName="p" {...{
          "href": "https://www.creativevillage.ne.jp/103727",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`セミナー登壇`}</a>{`の依頼があって、そこで話すことをまとめるために色々書いてみたところ、`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/cd2f69ee-264e-48ff-a313-11e33b191fa1_gnotes-jam1.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/f9964e8f-a7da-4911-88ac-817a698633fe_gnotes-jam2.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p>{`なんかこれを書いていると自分で納得感が得られることに気づき、もうこれでスライド作ったら良いんじゃない？って思うに至った。そして全部GoodNotes手書きでスライドを作ったのがこれ。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://speakerdeck.com/takazudo/hurontoendoenzinianarazhi-tuteokitai-jamstack-ru-men-bian",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`フロントエンドエンジニアなら知っておきたい「Jamstack」～入門編～`}</a></li>
      </ul>
      <p>{`このセミナーは、かなりスラスラ喋れた。`}<br parentName="p"></br>{`
`}{`なぜか思い起こしてみると、なんだか自分でこのスライドの内容を考えて作ったという実感が強かったからかもしれない。`}</p>
      <p>{`こういうスライドは多くの場合、KeynoteだとかPowerPointだとか、そういうスライドを作るためのソフトでやることが多く、自分もそうだったんだけれども、なんだか、そういうのを使って作っていると、スライドを完成させるためにスライドを作っているという風になることが、自分は結構よくあった。`}</p>
      <p>{`伝えたいことがあって話す。そのためにスライドを用意するという最初の前提を差し置いて、スライドを用意するという作業だけが残ってしまうような、そういう感覚に。そうなると、セミナー当日もただスライドを読み上げる感じになりがちになる。`}</p>
      <p>{`これに対して、自分で手書きで作った場合は、本当に「これ、自分がこう考えて書きました」感覚がとても強かった。これは手で書くという行為がそうさせるのだろうか。とにかく、結果的に手書きでスライドを作って良い結果になったと思う。`}</p>
      <p>{`これは、別にKeynoteやPowerPointでスライドを作ることを否定するわけではなくて。そういうしっかり作ったスライドと共に、分かりやすく話を伝えられる人というのは、自分の中で考えを整理し、推敲した結果をスライドとして完成させることができているのだと思う。そこに至るまでには「考えを整理する」という過程を経ていて。`}</p>
      <p>{`ここで自分の場合「手で書く」ということが、このプロセスを大いに促進させるものであるのかもしれない。これはおそらく自分の中で欠落していたプロセスであった。`}</p>
      <p>{`その後、同じ感じで以下のセミナーでもGoodNotesでスライドを作った。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://cssnite.doorkeeper.jp/events/131253",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`『ざっくりつかむCSS設計』を一緒に読もうの会`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3LXiyvV",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`【Amazon特別セット】『ざっくりつかむCSS設計』著者による動画講座付きセット`}</a></li>
      </ul>
      <p>{`多分これからも同じ方法で自分はスライドを作ると思う。仮に最終的にKeynote等に落とし込むことになっても、まずは手で書いてみると思う。`}</p>
      <h2 {...{
        "id": "図のラフを作るのに使う"
      }}>{`図のラフを作るのに使う`}</h2>
      <p>{`そういうことがあってGoodNotesを使いまくるという経験を経て、その後はGoodNotesが板に付いたというか、自然と使える感覚になった。仕事の中では、図のラフを書いたり、他の人が書いた図に対してフィードバックをするのによく使う。Macでコピーした画像やテキストは、GoodNotesでそのままペーストできるので便利。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/a61ab272-c4be-4f3a-b461-5bd0a313852c_misc-02.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p>{`自分の場合、仕事で図版を作るときは、そういう図がちょろっと作れまっせみたいなサービスをよく使う。使ってるのはこのへん。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://whimsical.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Whimsical`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://balsamiq.com/wireframes/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Balsamiq mockups`}</a></li>
      </ul>
      <p>{`こういうサービスで図を作る際、GoodNotesを使うようになった以降は「まずとりあえずGoodNotesでラフを書く」というところから始める事が多くなった。この結果、さっさと図を完成させられていると感じる。これがなぜなのかハッキリとわからない。手間としてはむしろ一段階増えているはずだが。`}</p>
      <p>{`考えてみたのだけれどこれは多分、図版を作るという行為において、ソフトのオペレーション作業というのが、実はそれなりに高負荷だからなのかもしれない。どういうことかというと、図を作るということは、頭の中身思い描いた図形なりのセットを、最終的には画面に表示させる画像に落とし込むみたいなことになるが、ここで、一度作ってみたものを目にした時、そこではじめで気づくことがある。ここ意味わからんなとか、この謎のスペース何？とか、コレとアレの差がわからないとか。`}</p>
      <p>{`ところが、何かしらのソフトで作った図版は、それなりに良くできているように見えるので、なんとなく違和感があっても「まいっか」となってしまう。これに対して、手書きであると、すぐにその場で書き直すし、そもそも手書きで書いた時点でそれは「下書き」なので、ちょっと違和感があっても、最終的にソフトでしっかり作る時に、そのあたりが補正される。そんなこんなで、手書きで下書きを作るというステップを経ることで、さっさと分かりやすい図版が作れる気がする。`}</p>
      <h2 {...{
        "id": "ペンと紙で書くことは人間にとって直感的な行為"
      }}>{`ペンと紙で書くことは人間にとって直感的な行為`}</h2>
      <p>{`話は変わるのだけれど、以下の動画にて、`}</p>
      <Youtube url="https://youtu.be/NeTrh3TOpCA" mdxType="Youtube" />
      <p>{`James Wiltshireという人が、曲の制作で悩んで終わってしまう人に対してアドバイスをしている。その内容というのが、コンピューターに向かう時間を減らして、まずは紙とペンで曲の構成を書き起こせというもの。彼曰く、そのように紙とペンを使って考えたほうが、人は遥かにクリエイティブになれるとのこと。`}</p>
      <p>{`その理由として挙げているのが、紙とペンという道具は、3,4際の頃からお父さんお母さんの絵を書いたりなど、人が道具を使うのを始めた頃から使い慣れている物なので、頭の中にあるイメージをほぼ自動的に表現できるというもの。`}</p>
      <p>{`この動画を見たのはGoodNotesを使い始めてからかなり後だったものの、彼の言っていることは、自分がGoodNotesを使うことで感じていた感覚と非常にマッチした。前述のソフトのオペレーションが挟まることの負荷という話も、まさにこれと同じことという気がする。`}</p>
      <h2 {...{
        "id": "思考の振り返りと整理のために使う"
      }}>{`思考の振り返りと整理のために使う`}</h2>
      <p>{`ほか、趣味の範囲でいうと、他には例えばゲームをする時にノートを取るようになった。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/dd512f5a-fd4a-4114-94bf-6da2d4739b90_gnotes-elden.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/d940efb0-8d48-4d20-bb5a-11372ec5316d_oni.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p>{`前者はELDEN RINGで、どういう武器や魔法を装備しようかという計画を立てている。後者はOxygen Not Includedというゲームで、1ゲーム終わったら、何がそのゲームで良くなかったのかを書いて振り返っている。`}</p>
      <p>{`Oxygen Not Includedというシミューレーションゲームは、一度ゲームを初めてからゲームオーバーになるまでには10時間以上の時間がかかるやつで、おまけにとても難易度が高く、一度失敗したことを振り返って次に生かさないと、永遠にクリアできないやつ（という認識）。自分はまだ一度もゴールまで達していない。`}</p>
      <p>{`なんか試しにノートに書いてみるかと思ってやってみたのだけれども、このOxygen Not Includedノートはもう22ページもある。見直してみても結構忘れてるので、そういうのが残るっていうのも結構いい感じがする。フツーにゲームの楽しみ方として今まで考えたことの無いものだった。`}</p>
      <p>{`ほか、最近また打ち込み音楽みたいのを再開していて、それをやっている間に考えたこと／気付いたこと／制作の経緯をノートにするようにしてる。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/0289de1e-d8af-4d1b-8fb7-62b90de07564_track1.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/35312b6d-d643-4c55-91c5-36d61dbdff80_track2.jpg?auto=compress,format&w=1269&h=792",
          "alt": null
        }}></img></p>
      <p>{`自分的にはこれは劇的に有効な方法だと思った。`}</p>
      <p>{`なぜなら、なんかそういう音楽をやるという行為について、人によってやりようは三者三様であり、一概にどうこう言えないものであると思うのだけど、自分の場合、答えがわからないまま迷走しているという状態になることがメチャクチャよくある。そういう状態になると、例えば5時間とかPCに向かっているにも関わらず、何が進んでいるのか何も進んでないのかよくわからんみたいな感じになる。いわゆるWriter's blockというやつ。`}</p>
      <p>{`そういう時でも、何やってたのか、何考えていたのかってのを書き起こすだけで、その時間は無駄では無かったことを確認することが出来る感じがする。このノートを取るようになってから、人というのはすぐに過去に考えたり思ったりしたことを忘れてしまうものだと感じるようになった。悩みまくってても、寝て起きたら忘れている。そういう時にノートがあるとその感覚を思い起こせる。`}</p>
      <p>{`もはやこうなってくると、ノートを書いてとっておくというよりも、考えるプロセスの一環としてノートを使うという感覚に近いものを感じる。ちなみにこの文章を書くという行為自体にも、自分にとってはそういう意味がある。`}</p>
      <p>{`要するにGoodNotesというのは自分にとってそういう道具となった。`}</p>
      <h2 {...{
        "id": "その他"
      }}>{`その他`}</h2>
      <p>{`iPadのフツーのフィルムとかだとツルツル滑って字がへにょへにょになるので、以下みたいなやつは絶対使ったほうが良いです。ザラザラになるのでかなりアリ。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/38s31q5",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`紙のような描き心地フィルム`}</a></li>
      </ul>
      <p>{`あとはTakazudoは物理的に物を多く持ちたくない人間なので、そういうとりあえずデジタルデータに統合したいみたいに思ってる人も、GoodNotesなやつは良い気がします。どこぞのDropboxのディレクトリに画像があるとかよりも、「GoodNotesのノート」というカテゴライズでまとまっていることも、それなりに良いポイントな気がする。`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`以上、GoodNotesについてつらつらと書きました。`}<br parentName="p"></br>{`
`}{`個人的には16inchぐらいのiPadが欲しいので是非お願いします🙏`}</p>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      